import one from "../../assets/photos/1.jpg";
import two from "../../assets/photos/2.jpg";
import three from "../../assets/photos/3.jpg";
// import four from "../../assets/photos/4.jpg";
import five from "../../assets/photos/5.jpg";
import six from "../../assets/photos/6.jpg";
import seven from "../../assets/photos/7.jpg";
import eight from "../../assets/photos/8.jpg";
import nine from "../../assets/photos/9.jpg";
// import ten from "../../assets/photos/10.jpg";
import eleven from "../../assets/photos/11.jpg";
import twelve from "../../assets/photos/12.jpg";
import thirteen from "../../assets/photos/13.jpg";
import fourteen from "../../assets/photos/14.jpg";
import fithteen from "../../assets/photos/15.jpg";
import sixteen from "../../assets/photos/16.jpg";
import seventeen from "../../assets/photos/17.jpg";


export const photos = [
    {
        src: one,
        width: 2,
        height: 2
    },
    {
        src: two,
        width: 3,
        height: 2
    },
    {
        src: three,
        width: 2,
        height: 1
    },
    // {
    //     src: four,
    //     width: 4,
    //     height: 3
    // },
    {
        src: five,
        width: 4,
        height: 3
    },
    {
        src: six,
        width: 3,
        height: 2
    },
    {
        src: seven,
        width: 4,
        height: 3
    },
    {
        src: eight,
        width: 4,
        height: 3
    },
    {
        src: nine,
        width: 4,
        height: 3
    },
    // {
    //     src: ten,
    //     width: 4,
    //     height: 3
    // },
    {
        src: eleven,
        width: 2,
        height: 1
    },
    {
        src: twelve,
        width: 2,
        height: 1
    },
    {
        src: thirteen,
        width: 2,
        height: 1
    },
    {
        src: fourteen,
        width: 4,
        height: 3
    },
    {
        src: fithteen,
        width: 4,
        height: 3
    },
    {
        src: sixteen,
        width: 4,
        height: 3
    },
    {
        src: seventeen,
        width: 4,
        height: 3
    },
];