import React from 'react';

 
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export class Faq extends React.Component {
    
    public render(): React.ReactNode {
        return (
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Is there a minimum length of stay?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Most locations require a 30 day minimum stay in North America, with some properties requiring 60-90 day minimums. 
                            Outside North America, most locations are available for less than 30 days.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What is included in a Nomad Temporary Housing apartment?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Nomad provides everything you need for living and working. The apartments have a wide array of 
                            furniture, housewares, linens, electronics and artwork. All utilities are included, as is cable/streaming 
                            TV service, high speed internet, and basic telephone service. Housekeeping is standard in some 
                            areas such as London; however, it is optional in most areas. Long distance calls are an 
                            additional cost. Parking is free and included at most locations, but can be optional or an 
                            extra fee in certain cities such as London and New York.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What kind of properties does Nomad have in its portfolio?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Most of our locations are in upscale apartment communities built in the last 5-12 years. 
                            Suburban areas feature mostly three or four story garden style apartment communities. 
                            These properties have a wide array of recreational amenities such as large swimming 
                            pools and spas, fitness centers, and clubhouses with additional amenities. 
                            Urban properties are mostly mid-rise or high-rise buildings, many with 24/7 doormen 
                            or concierge staffs. These properties occasionally have swimming pools, many have 
                            fitness centers and guest recreation floors. Practically all of our communities have some 
                            form of controlled access in the buildings and/or parking areas.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Are pets welcome?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Yes! Nomad offers pet friendly locations in every city we serve. 
                        Some locations have weight limitations or breed restrictions on certain types of dogs. 
                        Pet deposits are required and some locations also require a monthly pet fee in the rent 
                        to allow for the additional property care from pet occupied apartments.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Are Nomad temporary accommodation’s smoke free?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Nomad has both smoke free apartments and apartments where cigarette smoking is permitted. 
                        However, even apartments that are designated to allow cigarette smoking may charge 
                        up to $400 at departure to remove the smoke odor from apartment furniture, carpeting, 
                        bedding and even walls. Smoking in a smoke free designated apartment can be even more 
                        expensive to recondition, and will be charged to you.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );
    }
}