/* eslint-disable */

import React from "react";
import BGRSLogo from "../../assets/images/BGRS-movexpert-sm.png";
import NomadLogo from "../../assets/images/nomad-logo-horizontal.png";
import BuildingIcon from "../../assets/images/buildings.png";
import BgCover from "../../assets/images/hero-bg.jpg";
import { TempHousing } from "../../components/TempHousing/TempHousing";
import { AboutNomad } from "../../components/AboutNomad/AboutNomad";
import { Faq } from "../../components/Faq/Faq";
import { ContactUs } from "../../components/ContactUs/ContactUs.js";
import { PhotoGallery2 } from "../../components/PhotoGallery/PhotoGallery2.js";
import { ComingSoon } from "../../components/ComingSoon/ComingSoon";
import { PaymentAndTerms } from "../../components/PaymentAndTerms/PaymentAndTerms";
import { EconomyAlternative } from "../../components/EconomyAlternative/EconomyAlternative";
import { RantalRates } from "../../components/RentalRates/RentalRates";

export interface HomeProps {
    readOnly? : boolean;
}

export interface HomeState {
    tabs: Array<any>;
    activeTab: number;
}

export class Home extends React.Component<HomeProps, HomeState> {

    public state:HomeState = {
        tabs: [],
        activeTab: 0
    }

    public componentDidMount():void {
        const tabs = [];
        //tabs.push(`What is Temporary Housing?`,`About nomad`, `FAQ`, `Contact Us`)
        tabs.push(`What is Temporary Housing?`,`About nomad`,`Rental Rates`, `Photo Gallery`, `Economy Alternative`, `Payment & Terms`, `FAQ`, `Contact Us`)
        this.setState({tabs});
    }
    // eslint:disable 
    public render(): React.ReactNode {
         return (
            <>
            <div id="brand" className="wrap" >
                <div className="container">
                    <div className="row">
                        <div className="col col-md-6 col-sm-6 col-xs-6">
                            <img src={BGRSLogo} alt="bgrs_logo" />
                        </div>
                        <div className="col col-md-6 col-sm-6 col-xs-6">
                            <img  className="nomad-logo pull-right"  src={NomadLogo} alt="nomad_logo" />
                            {/* <img  className="nomad-logo pull-right"  src="../assets/images/nomad-logo-horizontal.png" alt="nomad_logo" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div id="hero" className="wrap">
                <div className="bg-cover" style ={ {backgroundImage: `url(${BgCover})` }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col col-md-12">
                        <h1>Welcome to <br /><b>Nomad Temporary Housing</b> for <b>moveXpert</b></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div id="header" className="wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-12 icon-graphic">
                        <img id="building-icon" src={BuildingIcon} alt="building-icon"/>
                            

                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-12">
                            <div >
                                <h2>Short Term rental Housing</h2>
                                <p>
                                    Nomad Temporary Housing provides fully furnished and accessorized temporary apartments for 30 days or longer, in major markets around the world.  Whether you are traveling alone, 
                                    or with your family, Nomad has you covered with one bedroom, two bedroom, and three bedroom floor plans. This is turn key living at its best: each unit includes furniture, 
                                    housewares, linens, dishes, cable or streaming TV, WiFi, and all utilities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="nav" className="wrap">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-12 col-xs-12">
                            <ul className="nav nav-pills" role="tablist">
                               {this.renderTabs()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                <div id="body" className="wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-8 col-xs-12 tabs-ui">
                                {this.renderBody()}
            
                                {/* <uib-tabset active="active" >
                                    <uib-tab index="0" ><div ng-include="'partials/temp-housing.html'"></div></uib-tab>
                                    <uib-tab index="1" ><div ng-include="'partials/about.html'"></div></uib-tab>
                                    <uib-tab index="2" ><div ng-include="'partials/rates.html'"></div></uib-tab>
                                    <uib-tab index="4" ><div ng-include="'partials/gallery.html'"></div></uib-tab>
                                    <uib-tab index="3" ><div ng-include="'partials/economy.html'"></div></uib-tab>
                                    <uib-tab index="5" ><div ng-include="'partials/terms.html'"></div></uib-tab>
                                    <uib-tab index="6" ><div ng-include="'partials/questions.html'"></div></uib-tab>
                                    <uib-tab index="7" ><div ng-include="'partials/contact.html'"   ></div></uib-tab>
                                </uib-tabset> */}
            
            
                            </div>
                            <div className="col-md-3 col-sm-4 col-xs-12">
                                <div className="bg-pattern sidebar-box">
                                    {/* <svg version="1.1" id="starburst" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 271.5 265.1" style="enable-background:new 0 0 271.5 265.1; width: 35px; height: 35px;" xml:space="preserve" >
                            <g>
                                <g>
                                    <g>
                                        <path style="fill:#F8752E;" d="M271.5,132.4c0,9.6-16.4,17.6-18.4,26.8c-2,9.6,9.2,23.6,4.8,32c-4,8.8-22.4,8.8-28,16
                                            c-6,7.6-2,25.2-9.6,31.2s-23.6-2-32.4,2.4c-8.4,4-12.4,21.6-22,24c-9.2,2-20.4-12-30-12s-21.2,14-30,12c-9.6-2-13.6-19.6-22-24
                                            c-8.8-4-24.8,3.6-32.4-2.4s-3.6-23.6-9.6-30.8c-6-7.6-24-7.6-28-16s7.2-22.8,4.8-32c-2-9.2-18.4-16.8-18.4-26.8
                                            c0-9.6,16.4-17.6,18.4-26.8c2-9.6-9.2-23.6-4.8-32c4-8.8,22.4-8.8,28-16c6-7.6,2-25.2,9.6-31.2s23.6,2,32.4-2.4
                                            c8.4-4,12.4-21.6,22-24c9.2-2,20.4,12,30,12s21.2-14,30-12c9.6,2,13.6,19.6,22,24c8.8,4,24.8-3.6,32.4,2.4s3.6,23.6,9.6,31.2
                                            c6,7.6,24,7.6,28,16s-7.2,22.8-4.8,32C255.5,114.7,271.5,122.8,271.5,132.4z"/>
                                    </g>
                                </g>
                                <g>
                                    <path style="fill:#F2F2F2;" d="M90.3,102c0-6.1,2-11.1,5.9-14.9c4-3.8,9.2-5.7,15.6-5.7c6.5,0,11.8,1.9,15.7,5.6s5.9,8.9,5.9,15.3
                                        v4.9c0,6.2-2,11.1-5.9,14.8c-4,3.7-9.2,5.6-15.6,5.6c-6.5,0-11.7-1.9-15.7-5.6c-4-3.8-6-8.8-6-15.2V102z M103.5,107.3
                                        c0,2.8,0.8,5,2.4,6.6c1.6,1.7,3.6,2.5,6.2,2.5c2.6,0,4.6-0.8,6.1-2.5c1.5-1.7,2.3-4,2.3-6.8V102c0-2.7-0.8-5-2.3-6.7
                                        c-1.5-1.7-3.6-2.5-6.2-2.5c-2.5,0-4.5,0.8-6.1,2.5c-1.5,1.7-2.3,4-2.3,7V107.3z M115.9,174.9l-9.6-5.2l48.6-77.8l9.6,5.2
                                        L115.9,174.9z M137.9,158c0-6.2,2-11.1,6-14.9c4-3.8,9.2-5.6,15.6-5.6c6.5,0,11.7,1.9,15.7,5.6c4,3.7,6,8.8,6,15.3v4.9
                                        c0,6.1-2,11-5.9,14.8c-3.9,3.8-9.1,5.6-15.7,5.6c-6.6,0-11.8-1.9-15.8-5.7c-4-3.8-5.9-8.8-5.9-15V158z M151.1,163.4
                                        c0,2.5,0.8,4.7,2.5,6.5s3.7,2.7,6.2,2.7c5.6,0,8.3-3.1,8.3-9.3V158c0-2.7-0.8-5-2.3-6.6c-1.5-1.7-3.6-2.5-6.2-2.5
                                        c-2.6,0-4.6,0.8-6.2,2.5c-1.5,1.7-2.3,4-2.3,6.8V163.4z"/>
                                </g>
                            </g>
                            </svg> */}
                                <div className="title">moveXpert SPECIAL</div>
                                    <div className="text">
                                        Free Bi-weekly<br />
                                        Housekeeping
                                    </div>
                                </div>
                                {/* <div className="bg-color sidebar-box" ng-controller="ModalCtrl">
                                    <button className="btn btn-warning" ng-click="open('Request Options','partials/request-info.html','lg')">Request Options</button>
                                </div> */}
                                <div className="bg-color sidebar-box">
                                    <a href="http://nomadtemphousing.com" target="blank" className="btn btn-warning">Visit Our Website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div id="footer" className="wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                Should you have any questions about your Lump Sum policy, entitlement or any other questions relating to your assignment, please contact a BGRS moveXpert via the “Help” section of www.moveXpert.com or by phone at 1.800.210.0299 ext 61+6042 or Direct Dial at +1 480.778.6042.
                            </div>
                            <div className="col-md-4">
                                <div className="social-icons">
                                    <div className="fa fa-twitter"></div>
                                    <div className="fa fa-linkedin"></div>
                                    <div className="fa fa-facebook"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    protected handleTabClicked = ( activeTab: number ): any =>
     (event: React.MouseEvent): void  => {
        console.log("handleTabClicked activeTab", activeTab);
        this.setState({activeTab});
    }
    
    protected renderTabs = (): React.ReactNode => {
        return !!this.state.tabs && this.state.tabs.map((item, index) => {
            const className_attribute = ( this.state.activeTab === index ) ? "active" : "";
            return <li key={index} role="presentation" className={className_attribute}><a onClick={this.handleTabClicked(index)} role="tab" data-toggle="tab">{item}</a></li>
            }
        );
    }

    protected renderBody = (): React.ReactNode => {
        switch ( this.state.activeTab ) {
            case 0: // what is Tempory Housing
                return <TempHousing />
            case 1: // About Nomad
                return <AboutNomad />
            case 2: // Rental Rates
                return <RantalRates />
            case 3: // Photo Gallery
                return <PhotoGallery2 />
            case 4: // Economy Alternative
                return <EconomyAlternative />
            case 5: // Payment ann Terms
                return <PaymentAndTerms />
            case 6: // Faq
                return <Faq />
            case 7: // Contact Us
                return <ContactUs />
        }
    }
}