
import React from "react";
import { Router } from "../Routers/Router";

export class PublicWrapper extends React.Component<{}> {

    public render(): React.ReactNode {
        return(
            <Router />
        );
    }
}