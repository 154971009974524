import React from "react";

export class TempHousing extends React.Component<{}> {
    public render(): React.ReactNode {
        return (
            <>
            <h2>What is Temporary Housing?</h2>
            <p>Temporary housing, also known as corporate housing (and serviced apartments internationally), is a lodging segment providing temporary accommodations for needs of 30 days or longer. Unlike a hotel, temporary apartments are located in residential apartment communities where the short term apartment is next to neighbors who reside permanently at the community. 
                Temporary housing typically offers larger square footage and costs less than a comparable suite hotel.</p>
            <p>Corporate apartments are in newer, upscale apartment communities and have the following amenities:</p>
            <ul>
                <li>Complete apartment furniture package, including living room furniture, dining furniture, bedroom furniture, artwork, televisions, and small kitchen appliances.</li>
                <li>Linen and bedding packages including towels, sheets, comforters, blankets, and pillows.</li>
                <li>Houseware items such as dishes, cookware, silverware, and other needed items.</li>
                <li>Electricity, cable/streaming TV, high speed internet, and telephone service included.</li>
                <li>Nomad handles any issue that needs attention during your stay, with full service, end to end.</li>
            </ul>
            </>
        );
    }
}