export const ratesData = [
    {
        state: "Alaska",
        items: [
            {
                city : "Anchorage",
                one_bedroom: "$100 - $125",
                two_bedroom: "$110 - $145",
                three_bedroom: "$125  - $155"
            },
            {
                city : "Juneau",
                one_bedroom: "$110 - $135",
                two_bedroom: "$120 - $155",
                three_bedroom: "$135 - $160"
            }
        ]
    },
    {
        state: "Alabama",
        items: [
            {
                city : "Birmingham",
                one_bedroom: "$95 - $125",
                two_bedroom: "$100 - $130",
                three_bedroom: "$120  - $145"
            },
            {
                city : "Huntsville",
                one_bedroom: "$100 - $130",
                two_bedroom: "$110 - $140",
                three_bedroom: "$120 - $145"
            }
        ]
    },
    {
        state: "Arizona",
        items: [
            {
                city : "Phoenix / Scottsdale / Tempe / Mesa",
                one_bedroom: "$115 - $140",
                two_bedroom: "$125 - $145",
                three_bedroom: "$135 - $155"
            },
            {
                city : "Tucson",
                one_bedroom: "$95 - $115",
                two_bedroom: "$105 - $125",
                three_bedroom: "$110 - $130"
            }
        ]
    },
    {
        state: "Arkansas",
        items: [
            {
                city : "Little Rock",
                one_bedroom: "$85 - $100",
                two_bedroom: "$95 - $110",
                three_bedroom: "$105 - $120"
            }
        ],
    },
    {
        state: "California",
        items: [
            {
                city : "Sacramento",
                one_bedroom: "$115 - $130",
                two_bedroom: "$125 - $145",
                three_bedroom: "$135 - $150"
            },
            {
                city : "San Diego Area",
                one_bedroom: "$150 - $165",
                two_bedroom: "$175  - $200",
                three_bedroom: "$180 - $220"
            },
            {
                city : "Orange County",
                one_bedroom: "$165 - $195",
                two_bedroom: "$195 - $225",
                three_bedroom: "$195 - $240"
            },
            {
                city : "Los Angeles Downtown",
                one_bedroom: "$175 - $200",
                two_bedroom: "$185 - $215",
                three_bedroom: "$180 - $280"
            },
            {
                city : "Los Angeles Area",
                one_bedroom: "$150 - $190",
                two_bedroom: "$175  - $240",
                three_bedroom: "$180 - $280"
            },
            {
                city : "San Francisco Downtown",
                one_bedroom: "$210 - $295",
                two_bedroom: "$260 - $325",
                three_bedroom: "$325 - $395"
            },
            {
                city : "Silicon Valley",
                one_bedroom: "$190 - $275",
                two_bedroom: "$250  -$320",
                three_bedroom: "$300  - $400"
            },
            {
                city : "SF East Bay",
                one_bedroom: "$180 - $210",
                two_bedroom: "$230 - $250",
                three_bedroom: "$265 - $295"
            }
        ],
    },
    {
        state: "Colorado",
        items: [
            {
                city : "Denver",
                one_bedroom: "$125 - $145",
                two_bedroom: "$140 - $165",
                three_bedroom: "$150 - $175"
            },
            {
                city : "Fort Collins",
                one_bedroom: "$135 - $155",
                two_bedroom: "$145 - $165",
                three_bedroom: "$165 - $185"
            }
        ],
    },
    {
        state: "Connecticut",
        items: [
            {
                city : "Stamford",
                one_bedroom: "$165 - $200",
                two_bedroom: "$190 - $215",
                three_bedroom: "$200 - $230"
            }
        ],
    },
    {
        state: "Delaware",
        items: [
            {
                city : "Wilmington",
                one_bedroom: "$105 - $135",
                two_bedroom: "$110 - $145",
                three_bedroom: "$115 - $160"
            }
        ],
    },
    {
        state: "District of Columbia",
        items: [
            {
                city : "Washington D.C",
                one_bedroom: "$185 - $200",
                two_bedroom: "$195 - $215",
                three_bedroom: "$210 - $230"
            }
        ],
    },
    {
        state: "Florida",
        items: [
            {
                city : "Miami / Ft. Lauderdale /  West Palm",
                one_bedroom: "$145 - $165",
                two_bedroom: "$175 - $195",
                three_bedroom: "$190 - $210"
            },
            {
                city : "Jacksonville",
                one_bedroom: "$110 - $120",
                two_bedroom: "$125 - $140",
                three_bedroom: "$135 - $155"
            },
            {
                city : "Orlando",
                one_bedroom: "$120 - $125",
                two_bedroom: "$130 - $150",
                three_bedroom: "$140 - $160"
            },
            {
                city : "Tampa",
                one_bedroom: "$110 - $125",
                two_bedroom: "$120 - $135",
                three_bedroom: "$130 - $155"
            }
        ],
    },
    {
        state: "Georgia",
        items: [
            {
                city : "Atlanta Area",
                one_bedroom: "$110 - $160",
                two_bedroom: "$135 - $200",
                three_bedroom: "$180 - $250"
            }
        ],
    },
    {
        state: "Hawaii",
        items: [
            {
                city : "Honolulu / Oahu",
                one_bedroom: "$110 - $160",
                two_bedroom: "$135 - $200",
                three_bedroom: "$180 - $250"
            },
            {
                city : "Outer Islands",
                one_bedroom: "$200 - $250",
                two_bedroom: "$250 - $300",
                three_bedroom: "$260 - $320"
            }
        ],
    },
    {
        state: "Idaho",
        items: [
            {
                city : "Boise",
                one_bedroom: "$110 - $160",
                two_bedroom: "$135 - $200",
                three_bedroom: "$180 - $250"
            },
        ],
    },
    {
        state: "Illinois",
        items: [
            {
                city : "Chicago Downtown",
                one_bedroom: "$160 - $185",
                two_bedroom: "$200 -$250",
                three_bedroom: "$250 - $300"
            },
            {
                city : "Chicago Suburbs",
                one_bedroom: "$145 - $165",
                two_bedroom: "$160 - $195",
                three_bedroom: "$225 - $300"
            },
        ],
    },
    {
        state: "Indiana",
        items: [
            {
                city : "Plainfield",
                one_bedroom: "$115 - $125",
                two_bedroom: "$130 - $140",
                three_bedroom: "$135 - $155"
            },
            {
                city : "Indianapolis",
                one_bedroom: "$100 - $120",
                two_bedroom: "$110 - $130",
                three_bedroom: "$120 - $150"
            },
        ],
    },
    {
        state: "Iowa",
        items: [
            {
                city : "Des Moines",
                one_bedroom: "$100 - $120",
                two_bedroom: "$115 - $130",
                three_bedroom: "$120 - $145"
            },
        ],
    },
    {
        state: "Kansas",
        items: [
            {
                city : "Kansas City",
                one_bedroom: "$120 - $135",
                two_bedroom: "$135 - $155",
                three_bedroom: "$145 - $165"
            },
            {
                city : "Overland Park",
                one_bedroom: "$115 - $130",
                two_bedroom: "$130 - $150",
                three_bedroom: "$135 - $150"
            },
        ],
    },
    {
        state: "Kentucky",
        items: [
            {
                city : "Lexington",
                one_bedroom: "$100 - $115",
                two_bedroom: "$110 - $125",
                three_bedroom: "$115 - $135"
            },
            {
                city : "Florence",
                one_bedroom: "$100 - $115",
                two_bedroom: "$110 - $125",
                three_bedroom: "$115 - $135"
            },
            {
                city : "Louisville",
                one_bedroom: "$105 - $120",
                two_bedroom: "$115 - $135",
                three_bedroom: "$120 - $140"
            },
        ],
    },
    {
        state: "Louisiana",
        items: [
            {
                city : "Baton Rouge",
                one_bedroom: "$115 - $130",
                two_bedroom: "$125 - $135",
                three_bedroom: "$135 - $145"
            },
            {
                city : "New Orleans",
                one_bedroom: "$125 - $145",
                two_bedroom: "$140 - $155",
                three_bedroom: "$150 - $165"
            },
        ],
    },
    {
        state: "Maine",
        items: [
            {
                city : "Waltham",
                one_bedroom: "$165 - $185",
                two_bedroom: "$175 - $225",
                three_bedroom: "$230 - $270"
            },
        ],
    },
    {
        state: "Maryland",
        items: [
            {
                city : "Baltimore & Other Areas",
                one_bedroom: "$130 - $145",
                two_bedroom: "$150 - $165",
                three_bedroom: "$160 - $175"
            },
        ],
    },
    {
        state: "Massachusetts",
        items: [
            {
                city : "Boston",
                one_bedroom: "$190 - $275",
                two_bedroom: "$230 - $290",
                three_bedroom: "$220 - $250"
            },
            {
                city : "Cambridge",
                one_bedroom: "$170 - $185",
                two_bedroom: "$190 - $225",
                three_bedroom: "$220 - $250"
            },
            {
                city : "Waltham",
                one_bedroom: "$175 - $190",
                two_bedroom: "$180 - $220",
                three_bedroom: "$210 - $240"
            },
        ],
    },
    {
        state: "Michigan",
        items: [
            {
                city : "Detroit Metro",
                one_bedroom: "$100 - $115",
                two_bedroom: "$115 - $135",
                three_bedroom: "$125 - $145"
            },
            {
                city : "Detroit Suburbs",
                one_bedroom: "$95 - $120",
                two_bedroom: "$120 - $135",
                three_bedroom: "$125 - $145"
            },
        ],
    },
    {
        state: "Minnesota",
        items: [
            {
                city : "Minneapolis Metro",
                one_bedroom: "$120 - $135",
                two_bedroom: "$135- $150",
                three_bedroom: "$140 - $160"
            },
            {
                city : "Minneapolis Suburbs",
                one_bedroom: "$95 - $110",
                two_bedroom: "$110 - $125",
                three_bedroom: "$120 - $140"
            },
        ],
    },
    {
        state: "Mississippi",
        items: [
            {
                city : "Jackson",
                one_bedroom: "$110 - $125",
                two_bedroom: "$120 - $135",
                three_bedroom: "$130 - $145"
            },
        ],
    },
    {
        state: "Missouri",
        items: [
            {
                city : "Kansas City",
                one_bedroom: "$120 - $135",
                two_bedroom: "$135 - $145",
                three_bedroom: "$140 - $155"
            },
            {
                city : "St. Louis",
                one_bedroom: "$120 - $135",
                two_bedroom: "$125 - $145",
                three_bedroom: "$130 - $145"
            },
            {
                city : "Springfield",
                one_bedroom: "$95 - $110",
                two_bedroom: "$110 - $125",
                three_bedroom: "$130 - $145"
            },
        ],
    },
    {
        state: "Montana",
        items: [
            {
                city : "Billings",
                one_bedroom: "$130 - $150",
                two_bedroom: "$140 - $160",
                three_bedroom: "$145 - $165"
            },
        ],
    },
    {
        state: "Nebraska",
        items: [
            {
                city : "Omaha",
                one_bedroom: "$80 - $105",
                two_bedroom: "$95 - $125",
                three_bedroom: "$110 - $135"
            },
        ],
    },
    {
        state: "Nevada",
        items: [
            {
                city : "Las Vegas",
                one_bedroom: "$90 - $120",
                two_bedroom: "$100 - $135",
                three_bedroom: "$120 - $145"
            },
        ],
    },
    {
        state: "New Hampshire",
        items: [
            {
                city : "Hartford",
                one_bedroom: "$115 - $135",
                two_bedroom: "$130 - $145",
                three_bedroom: "$140 - $150"
            },
        ],
    },
    {
        state: "New Jersey",
        items: [
            {
                city : "Morristown",
                one_bedroom: "$115 - $135",
                two_bedroom: "$130 - $145",
                three_bedroom: "$140 - $150"
            },
            {
                city : "Jersey City",
                one_bedroom: "$180 - $210",
                two_bedroom: "$230 - $270",
                three_bedroom: "$260 - $295"
            },
            {
                city : "Princeton",
                one_bedroom: "$145 - $160",
                two_bedroom: "$165 - $180",
                three_bedroom: "$175 - $190"
            },
        ],
    },
    {
        state: "New Mexico",
        items: [
            {
                city : "Albuquerque",
                one_bedroom: "$90 - $105",
                two_bedroom: "$100 - $110",
                three_bedroom: "$260 - $295"
            },
        ],
    },
    {
        state: "New York",
        items: [
            {
                city : "Manhattan",
                one_bedroom: "$210 - $275",
                two_bedroom: "$330 - $390",
                three_bedroom: "$400 - $440"
            },
            {
                city : "White Plains",
                one_bedroom: "$170 - $190",
                two_bedroom: "$225 - $245",
                three_bedroom: "$240 - $265"
            },
            {
                city : "Albany",
                one_bedroom: "$125 - $135",
                two_bedroom: "$130 - $160",
                three_bedroom: "$165 - $195"
            },
        ],
    },
    {
        state: "North Carolina",
        items: [
            {
                city : "Durham",
                one_bedroom: "$95 - $110",
                two_bedroom: "$115 - $125",
                three_bedroom: "$125 - $135"
            },
            {
                city : "Raleigh",
                one_bedroom: "$105 - $115",
                two_bedroom: "$115 - $125",
                three_bedroom: "$125 - $140"
            },
            {
                city : "Charlotte",
                one_bedroom: "$115 - $125",
                two_bedroom: "$125 - $145",
                three_bedroom: "$150 - $170"
            },
        ],
    },
    {
        state: "North Dakota",
        items: [
            {
                city : "Bismark",
                one_bedroom: "$110 - $120",
                two_bedroom: "$100 - $110",
                three_bedroom: "$130 - $140"
            },
        ],
    },
    {
        state: "Ohio",
        items: [
            {
                city : "Columbus",
                one_bedroom: "$115  - $125",
                two_bedroom: "$130 - $145",
                three_bedroom: "$150 - $165"
            },
            {
                city : "Cincinnati",
                one_bedroom: "$115  - $125",
                two_bedroom: "$130 - $145",
                three_bedroom: "$150 - $165"
            },
            {
                city : "Cleveland",
                one_bedroom: "$125 - $145",
                two_bedroom: "$145 - $155",
                three_bedroom: "$150 - $165"
            },
        ],
    },
    {
        state: "Oklahoma",
        items: [
            {
                city : "Tulsa",
                one_bedroom: "$105 - $115",
                two_bedroom: "$115 - $130",
                three_bedroom: "$125 - $140"
            },
            {
                city : "Oklahoma City",
                one_bedroom: "$105 - $120",
                two_bedroom: "$115 - $130",
                three_bedroom: "$125 - $140"
            },
        ],
    },
    {
        state: "Oregon",
        items: [
            {
                city : "Portland",
                one_bedroom: "$135- $150",
                two_bedroom: "$155 - $175",
                three_bedroom: "$165 - $180"
            },
        ],
    },
    {
        state: "Pennsylvania",
        items: [
            {
                city : "Pittsburgh",
                one_bedroom: "$140 - $160",
                two_bedroom: "$150 - $175",
                three_bedroom: "$165 - $195"
            },
            {
                city : "Philadelphia",
                one_bedroom: "$155 - $175",
                two_bedroom: "$180 - $195",
                three_bedroom: "$195 - $225"
            },
        ],
    },
    {
        state: "Rhode Island",
        items: [
            {
                city : "Providence",
                one_bedroom: "$150 - $170",
                two_bedroom: "$170 - $200",
                three_bedroom: "$190 - $210"
            },
        ],
    },
    {
        state: "South Carolina",
        items: [
            {
                city : "Columbia",
                one_bedroom: "$95 - $120",
                two_bedroom: "$120 - $135",
                three_bedroom: "$125 - $145"
            },
            {
                city : "Charleston",
                one_bedroom: "$95 - $120",
                two_bedroom: "$120 - $135",
                three_bedroom: "$125 - $145"
            },
        ],
        
    },
    {
        state: "South Dakota",
        items: [
            {
                city : "Sioux Falls",
                one_bedroom: "$105 - $110",
                two_bedroom: "$115 - $130",
                three_bedroom: "$125 - $140"
            },
        ],
    },
    {
        state: "Tennessee",
        items: [
            {
                city : "Franklin",
                one_bedroom: "$95 - $120",
                two_bedroom: "$120 - $135",
                three_bedroom: "$125 - $145"
            },
            {
                city : "Memphis",
                one_bedroom: "$90 - $110",
                two_bedroom: "$110 - $125",
                three_bedroom: "$120 - $140"
            },
            {
                city : "Nashville",
                one_bedroom: "$95 - $120",
                two_bedroom: "$120 - $135",
                three_bedroom: "$125 - $145"
            },
            {
                city : "Chattanooga",
                one_bedroom: "$95 - $120",
                two_bedroom: "$120 - $135",
                three_bedroom: "$125 - $145"
            },
        ],
    },
    {
        state: "Texas",
        items: [
            {
                city : "Houston",
                one_bedroom: "$125 - $160",
                two_bedroom: "$160 - $185",
                three_bedroom: "$175 - $230"
            },
            {
                city : "Dallas",
                one_bedroom: "$125 - $160",
                two_bedroom: "$160 - $185",
                three_bedroom: "$175 - $230"
            },
            {
                city : "El Paso",
                one_bedroom: "$80 - $105",
                two_bedroom: "$95 - $110",
                three_bedroom: "$110 - $125"
            },
            {
                city : "Austin",
                one_bedroom: "$125 - $160",
                two_bedroom: "$160 - $185",
                three_bedroom: "$175 - $230"
            },
        ],
    },
    {
        state: "Utah",
        items: [
            {
                city : "Salt Lake City",
                one_bedroom: "$115 - $140",
                two_bedroom: "145 - $160",
                three_bedroom: "$155 - $175"
            },
        ],
    },
    {
        state: "Virginia",
        items: [
            {
                city : "Northern Virgina Area",
                one_bedroom: "$165 - $180",
                two_bedroom: "$180 - $200",
                three_bedroom: "$200 - $225"
            },
            {
                city : "DC Suburbs",
                one_bedroom: "$150 - $200",
                two_bedroom: "$235 - $280",
                three_bedroom: "$270 - $340"
            },
        ],
    },
    {
        state: "Washington",
        items: [
            {
                city : "Seattle",
                one_bedroom: "$155 - $175",
                two_bedroom: "$180 - $195",
                three_bedroom: "$195 - $220"
            },
            {
                city : "Bellevue",
                one_bedroom: "$145 - $165",
                two_bedroom: "$170 - $185",
                three_bedroom: "$185 - $210"
            },
        ],
    },
    {
        state: "Wisconsin",
        items: [
            {
                city : "Madison",
                one_bedroom: "$95 - $120",
                two_bedroom: "$120 - $135",
                three_bedroom: "$125 - $145"
            },
        ],
    },
]