import React from "react";
import { HashRouter, Switch, Route} from 'react-router-dom';
import { Home } from "../pages/Home/Home"

export class Router extends React.Component<{}> {
    
    public render() : React.ReactNode {
        return (
            <HashRouter>
                <Switch>
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/" component={Home}/>
                </Switch>
            {/*<DevTools/>*/}
            </HashRouter>
        )
    }
    
    
}