import React, { useEffect } from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import './assets/css/app.css';
import "./App.css";
import { PublicWrapper } from "./wrappers/PublicWrapper";
import ReactGA from 'react-ga';

function App() {

    useEffect(() => {
        ReactGA.initialize('UA-82647632-2');
        ReactGA.pageview(window.location.pathname);
    }, []);
  
    return (
    <PublicWrapper />
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );

  
}

export default App;
